import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";



export function LandingCarousel({carouselData}) {

    function NewSlide({ image, title, description }) {
      return (
  
        <div className={`rounded-xl bg-cover bg-center m-auto h-full`} style={{ backgroundImage: image }} loading="lazy">
          <div className="rounded-xl bg-gradient-to-r from-glass to-transparent h-full">
  
            <div className="rounded-xl bg-gradient-to-t from-glass to-transparent m-auto align-center py-40 md:py-80 h-full" >
              <div className="flex flex-col pl-10 pr-20 text-overimage h-full m-auto md:grid md:grid-cols-2">
                <div className="drop-shadow-2xl text-left mr-auto ">
                  <p className="font-title text-left text-2xl md:text-5xl capitalize m-auto">
                    {title}
                  </p>
                  <p className="font-description font-light text-left text-lg md:text-xl pt-2 ">
                    {description}
                  </p>
                </div>
  
              </div>
            </div>
  
          </div>
        </div>
      )
    };
  
    return (
      <div className="z-0 pt-14 md:pt-24 align-middle m-auto" >
        <div className="mx-4 mt-4"><Carousel className="rounded-2xl " interval={8000} swipeable={false} autoPlay={true} showThumbs={false} showIndicators={false} showStatus={false} showArrows={false} infiniteLoop={true} onSwipeStart onSwipeEnd>
          {carouselData.map((obj, index) => {
            return (<NewSlide key={index} image={obj.img} title={obj.title} description={obj.description} />)
          })}
        </Carousel></div>
  
      </div>
    );
  };
  
export function Card({ image, title, content }) {
    return (
        <div className=" drop-shadow-md bg-cover bg-center size-full m-auto rounded-2xl flex" style={{ backgroundImage: image }}>

            <div className="rounded-2xl h-full w-full bg-gradient-to-t from-glass to-transparent grid h-full grid-rows-3 m-auto ">
                <div className="row-span-2">
                    
                </div>
                <div className="row-span-1 mx-4 text-left px-4 pb-4" >
                <div className="text-left font-title text-2xl md:text-4xl">
                        {title}
                    </div>
                    <div className="text-description font-description text-lg md:text-2xl">
                        {content}
                    </div>
                </div>

            </div>
        </div>
    )
};
import axios from "axios";


export async function GetCurrencyPrice(setPrices) {

    const symbols = [
        "USDTBRL",
        "BTCBRL",
      ];

    try {
      console.log("Fetching Prices")
      const updatedPrices = await Promise.all(
        symbols.map(async (symbol) => {
          const url = `https://api.binance.com/api/v3/ticker/price?symbol=${symbol}`;
          const res = await axios.get(url);
          return { symbol, price: res.data.price };
        })
      );

      setPrices(updatedPrices);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }
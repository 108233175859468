export function Section({ children, id, className, title }) {
    return (
        <section id={id} className={`text-overbackground text-center ${className}`}>
            <div>
                <div className="my-10">
                    <h1 className="font-title text-2xl md:text-5xl">
                        {title}
                    </h1>

                </div>
                <div className="font-descripiton bg-holder text-center m-auto flex flex-col gap-4 py-10">
                    
                <div className="grid md:grid-cols-2 gap-10 p-10 px-14 md:px-28">{children}
                </div>
                </div>
            </div>


        </section>
    )
};
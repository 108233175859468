import React from 'react';
import ReactDOM from 'react-dom/client';

import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './components/Home';
import Footer from './components/Footer.jsx';
import Navbar from './components/Navbar';
import NotFound from './common/NotFound.jsx';
import {Contact} from "./common/Contact.jsx";
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Navbar />
    <div className="h-screen m-auto grid-cols-1 antialiased subpixel-antialiased bg-background overflow-x-hidden">
      <BrowserRouter >
        <Routes>
          <Route path='*' element={ <NotFound/> } />
          <Route path="/" element={<Home />} />
          <Route path="/law" element={<Contact id="law" title="Canal de Comunicação para Autoridades" className="mt-20 m-auto h-full" />} />
          {/*<Route path="/services" component={Services} /> 
        <Route path="/contact" component={Contact} /> */}
        </Routes>
      </BrowserRouter>
      <Footer />
    </div>

  </React.StrictMode>
);

reportWebVitals(console.log);

import { useState, useEffect, react } from "react";

export function ClickExpand({ key, titleComponent, contentComponent, ...props }) {
    const [expandState, setExpandState] = useState(false);

    function onClickSetExpand(event) {
        console.log(event)
        setExpandState(!expandState);
    };

    return (
        <div className="size-full rounded-md" key={key} >
            <button className="flex align-middle m-auto flex-row size-full p-4" onClick={onClickSetExpand}>
                <img className="size-5 invert my-auto p-auto" src={`${expandState ? "/image/add.webp" : "/image/subtraction.webp"} `} alt="" />
                {titleComponent}
            </button>

            <div className={`${expandState ? "h-full" : "h-0 overflow-hidden"}`}>
                {contentComponent}

            </div>

        </div>
    );
};
import { react, useState, useEffect } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Loading } from "../common/Loading.jsx";

import { Section } from "../common/Section.jsx";
import { LandingCarousel } from "../common/LandingCarousel.jsx";
import { Card } from "../common/Card.jsx";
import { ClickExpand } from "../common/ClickExpand.jsx";
import { Contact } from "../common/Contact.jsx";
import { motion } from "framer-motion";

import { GetCurrencyPrice } from "../api/GetCurrencyPrice.jsx";

import Marquee from "react-fast-marquee";

import "../index.css";

function FAQ() {

  const faqList = {
    "O que é uma exchange?": "Uma exchange, no contexto financeiro, é uma plataforma online onde os usuários podem comprar, vender e negociar uma variedade de ativos financeiros, como criptomoedas, ações, títulos, commodities e moedas fiduciárias. Essas plataformas facilitam a troca de ativos entre compradores e vendedores, geralmente cobrando uma taxa por cada transação realizada. As exchanges de criptomoedas, por exemplo, são especialmente populares e permitem que os usuários comprem e vendam diferentes tipos de criptomoedas, como Bitcoin, Ethereum, entre outras.",
    "Quais moedas posso negociar através da Smart?": "USDT, USDC, Dólar Comercial E Bitcoin.",
  }

  return (
    <div id="faq" className=" border-none m-20 rounded-md bg-holder transition-all">
      <h1 className=" border-none text-xl md:text-3xl font-title text-center mx-auto text-header align-middle p-5">
        Perguntas Frequentes
      </h1>
      <div className="border-none">
        {Object.keys(faqList).map((key) => {
          return (
            ClickExpand({
              key: key,
              titleComponent:
                <h1 className="font-title font-bold mr-0 text-header text-xl md:text-3xl font-title text-left pl-4">
                  {key}
                </h1>,
              contentComponent:
                <p className="font-description opacity-80 text-description p-6 text-justify text-lg md:text-2xl">
                  {faqList[key]}
                </p>
            })
          )
        })}

      </div>
    </div>
  )
};

function About() {

  const cardInfo = [
    {
      "title": "",
      "image": "url('/image/cowork.webp')",
      "content": <p>Somos uma assessoria especializada em negociações de criptomoedas, oferecendo soluções ágeis e seguras para quem deseja comprar ou vender ativos digitais.
      </p>
    },
    {
      "title": "",
      "image": "url('/image/economist.webp')",
      "content": <p>Nossa missão é simplificar o processo para nossos clientes, eliminando a burocracia desnecessária e garantindo uma experiência fluida, desde o primeiro contato até a conclusão da transação.</p>
    },
    {
      "title": "",
      "image": "url('/image/support.webp')",
      "content": <p>Com uma equipe de especialistas e o uso das mais avançadas tecnologias de segurança, asseguramos que todas as operações sejam conduzidas dentro dos mais altos padrões de confiabilidade e proteção.</p>
    },
    {
      "title": "",
      "image": "url('/image/blogger.webp')",
      "content": <p>
        Atuamos de forma personalizada, adaptando nossos serviços às necessidades de cada cliente, seja ele iniciante no mercado de criptomoedas ou investidor experiente.
      </p>
    }
  ];

  return (
      <Section id="about" title="Quem Somos" children={cardInfo.map((obj, index) => {
        return (
          <Card key={index} content={obj.content} image={obj.image}></Card>
        )
      })}>

      </Section>
  );

};

function Services() {

  const cardInfo = [
    {
      "title": <p>Compra e Venda</p>,
      "image": "url('/image/negotiation.webp')",
      "content": <p>Negocie Criptoativos sem se preocupar.</p>
    },
    {
      "title": <p>Agilidade</p>,
      "image": "url('/image/touch.webp')",
      "content": <p>Faça tudo com o mínimo de burocracia.</p>
    },
    {
      "title": <p>Rendimentos</p>,
      "image": "url('/image/pizza.webp')",
      "content": <p>Com a Smart, seu lucro pode ser ainda maior!</p>
    },
    {
      "title": <p>Assessoria</p>,
      "image": "url('/image/team.webp')",
      "content": <p>Todo suporte necessário para seus investimentos.</p>
    }
  ];

  return (

      <Section id="services" title="Conheça Nossas Facilidades" children={cardInfo.map((obj, index) => {
        return (
          <Card key={index} title={obj.title} content={obj.content} image={obj.image}></Card>
        )
      })}>

      </Section>

      
  )

};

export default function Home() {


  const [prices, setPrices] = useState();


  useEffect(() => {
    GetCurrencyPrice(setPrices);
    const intervalId = setInterval(()=>{GetCurrencyPrice(setPrices)}, 320000);
    return () => clearInterval(intervalId);
  }, []);


  const getPriceForSymbol = (symbol) => {
    try {
      const entry = prices.find((entry) => entry.symbol === symbol);
      return entry ? entry.price : 'N/A';
    } catch {
      console.info('waiting...')
    }

  };

  const usdtPrice = parseFloat(getPriceForSymbol('USDTBRL')).toFixed(2);
  const btcPrice = parseFloat(getPriceForSymbol('BTCBRL')).toFixed(2);

  function CurrencyMarquee() {

    function CurrencyItem({ index, title, icon, price }) {
      const carosselItemStyle = "drop-shadow-lg m-4 h-full rounded-md text-left bg-holder w-60 align-center text-center mx-4";
      console.log(price);
      return (
        <div key={index} className={carosselItemStyle}>
          <div className="text-center grid grid-cols-3 py-4">
            {price.toLowerCase() === "R$ NaN".toLowerCase() ? <Loading className="m-auto size-10"></Loading> : <img className="size-10 m-auto col-span-1" src={icon} alt={`icon-${title.toLowerCase()}`} />}

            <div className="text-left col-span-2">

              <p className="font-title text-overholder font-bold">
                {title}
              </p>
              {price.toLowerCase() === "R$ NaN".toLowerCase() ? null : <p className="font-medium m-auto text-overholder">
                {price}
              </p>}
            </div>

          </div>
        </div>
      )
    };


    return (

      <div id="price" className="md:py-20">
        <div className="py-10">
          <Marquee autoFill gradient={true} gradientColor="rgba(0,0,0,0.1)" gradientWidth={50}>
            <CurrencyItem index={"usdt-price"} title={"USDT"} icon={"/currency/usdt.svg"} price={`R$ ${usdtPrice}`} />
            <CurrencyItem index={"btc-price"} title={"BTC"} icon={"/currency/bitcoin.svg"} price={`R$ ${btcPrice}`} />
            <CurrencyItem index={"usdc-price"} title={"USDC"} icon={"/currency/usdc.svg"} price={`R$ ${usdtPrice}`} />
            <CurrencyItem index={"btcc-price"} title={"BTC"} icon={"/currency/bitcoin.svg"} price={`R$ ${btcPrice}`} />
          </Marquee>
        </div>

      </div>
    )
  };

  const landingCarouselData = [
    {
      "title": "Amplie os horizontes do seu investimento",
      "description": "Adquira Bitcoin, USDT e outras moedas digitais.",
      "img": "url('/image/landing/buy.webp')"
    },
    {
      "title": "A Frente da Revolução",
      "description": "Auxiliamos pessoas e empresas a desbravar o mundo das criptomoedas com confiança e sucesso.",
      "img": "url('/image/landing/future.webp')"
    }
  ];

  return (
    <div className="gap-10">
    <LandingCarousel carouselData={landingCarouselData} />
    <CurrencyMarquee />
    <Services />
    <About />
    <Contact title="Contato" id="contact"/>
    <FAQ />
  </div>
  );
};
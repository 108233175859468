import React, { useState } from 'react';



const buttonStyle = "text-overbackground font-navbar transition-all hover:border-b-2 border-outline p-1 hover:text-primary";
const navbarStyle = "absolute z-40 overflow-hidden text-overbackground w-screen bg-background";
const mobileMenuStyle = "pl-10 opacity-100 transition-all";


function Menu({ className }) {

  const hrefPrice = "#price";
  const hrefAbout = "#about";
  const hrefService = "#services";
  const hrefContact = "#contact";

  return (

    <ul className={`${className}`}>
      <li className={buttonStyle}><a href="/">Página Inicial</a></li>
      <li className={buttonStyle}><a href={hrefPrice}>Preços</a></li>
      <li className={buttonStyle}><a href={hrefAbout}>Sobre</a></li>
      <li className={buttonStyle}><a href={hrefService}>Serviços</a></li>
      <li className={buttonStyle}><a href={hrefContact}>Contato</a></li>
      <li className={buttonStyle}><a href="#faq">FAQ</a></li>
    </ul>
  );
}


export default function Navbar() {
  const [menuState, setMenuState] = useState();

  function changeMenuState() {
    setMenuState(!menuState);
  };

  return (
    <nav className={navbarStyle}>

      <div className='flex flex-row '>
        <img src="/image/logo.png" className="h-10 md:h-full my-auto md:w-80 mr-auto ml-4 md:p-4 " alt="" />
        <div className="md:px-10 transition-all my-auto">
          <div className="flex items-center justify-between h-10">
            <div className="flex-shrink-0 hover:brightness-90">
              
            </div>
            <div className="hidden md:block mr-auto w-full">
            <Menu className="flex space-x-4"></Menu>
            </div>
          </div>
        </div >

        <div className="md:hidden ml-auto">
          <button className="text-white focus:outline-none m-5" onClick={changeMenuState}>
            <svg className="h-6 w-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
            </svg>
          </button>
        </div>
      </div>

      <Menu className={`md:hidden ${mobileMenuStyle} ${menuState ? "h-screen" : "opacity-0 h-0"}`} >
      </Menu>
    </nav>
  );
};


import { useState } from "react";
import axios from "axios";
import { useSearchParams } from "react-router-dom";
import { Loading } from "../common/Loading";

export function Contact({className, id, title}) {

  let [searchParams, setSearchParams] = useSearchParams();

  const [lang,] = useState(
    searchParams.get("lang") || "pt"
  );

  const [shouldShake, setShouldShake] = useState(false);
  const [loading, setLoading] = useState(false);

  const [name, setName] = useState();
  const [subject, setSubject] = useState();
  const [message, setMessage] = useState();
  const [mail, setMail] = useState();

  async function SendMessage() {
    if (!name || !mail || !subject || !message) {
      // Apply shake effect to fields that are empty
      setShouldShake(true);
      return;
    }

    // Reset the shake effect when the form is successfully submitted
    setShouldShake(false);
    setLoading(true);

    const cleanForm = () => {
      setName('');
      setMail('');
      setSubject('');
      setMessage('');
      setShouldShake(false);

      const nameInput = document.getElementById('name');
      if (nameInput) {
        nameInput.value = '';
      }

      const mailInput = document.getElementById('mail');
      if (mailInput) {
        mailInput.value = '';
      }

      const subjectInput = document.getElementById('subject');
      if (subjectInput) {
        subjectInput.value = '';
      }

      const messageInput = document.getElementById('message');
      if (messageInput) {
        messageInput.value = '';
      }
    };

    const requestData = {
      subject: subject,
      message: `Nome: ${name}\n E-mail: ${mail}\n Mensagem: ${message}`,
      email: String(process.env.REACT_APP_MAIL_API_CUSTOMER_SENDER),
      destination:
        process.env.REACT_APP_MAIL_DESTINATION ||
        "",
    };

    const url = `${process.env.REACT_APP_MAIL_API_URL}send-email`;

    const requestConfig = {
      "headers": {
        "app-secret": String(process.env.REACT_APP_MAIL_API_SECRET),
        "app-key": String(process.env.REACT_APP_MAIL_API_KEY),
        "accept": "application/json",
        "Content-Type": "application/json"
      }
    };

    try {
      const res = await axios.post(url, requestData, requestConfig);
      window.alert("Agradecemos seu contato, conversamos em breve!");
      cleanForm();
    } catch (error) {
      window.alert("Ocorreu um erro ao enviar o formulário.");
      setLoading(false);
    }
  } // Empty dependency array ensures the effect runs only once after mount

  return (
    <section id={id} className={`flex flex-col gap-4 mx-auto text-center text-overbackground ${className}`}>
      <div className="font-description m-auto my-10 md:w-1/2">
        <div className="font-title text-2xl md:text-5xl mb-10 ">
          <h2 className="text-2xl md:text-5xl lang-pt">{title}</h2>
          <div className="whats-app-wrapper"></div>
        </div>

          {loading? <Loading className="size-10 md:size-20 m-auto"></Loading> :         <div >
          <div>
            <div lang="pt-BR" dir="ltr">

              <form className="align-middle text-center gap-4 m-auto flex flex-col w-full h-full px-4">

                <div className="flex w-full gap-10">
                  <label className="" htmlFor="nome">
                    <span className="lang-pt">{lang === "pt" ? "NOME" : null}
                      {lang === "es" ? "NOMBRE" : null}
                      {lang === "en" ? "NAME" : null}</span>
                    :
                  </label>
                  <span className="flex w-full" data-name="nome">
                    <input id="name"
                      className={`w-full text-input pl-2 rounded-md rounded-md ${shouldShake ? 'shake-effect' : ''} `}
                      aria-required="true"
                      aria-invalid="false"
                      defaultValue=""
                      type="text"
                      name="nome"
                      required
                      onChange={(e) => setName(e.target.value)}
                    />
                  </span>
                </div>
                <div className="flex w-full  gap-10">
                  <label htmlFor="email">EMAIL:</label>
                  <span className="flex w-full" data-name="email">
                    <input className={`w-full text-input pl-2 rounded-md ${shouldShake ? 'shake-effect' : ''}`}
                      id="mail"
                      aria-required="true"
                      aria-invalid="false"
                      defaultValue=""
                      type="email"
                      name="email"
                      required
                      onChange={(e) => setMail(e.target.value)}
                    />
                  </span>
                </div>
                <div className="flex w-full gap-10">
                  <label htmlFor="assunto">
                    <span className="lang-pt">{lang === "pt" ? "ASSUNTO" : null}
                      {lang === "es" ? "SUJETO" : null}
                      {lang === "en" ? "SUBJECT" : null}</span>:
                  </label>
                  <span className="flex w-full" data-name="assunto">
                    <input
                      className={`w-full text-input pl-2 rounded-md ${shouldShake ? 'shake-effect' : ''}`}
                      id="subject"
                      aria-required="true"
                      aria-invalid="false"
                      defaultValue=""
                      type="text"
                      name="assunto"
                      required
                      onChange={(e) => setSubject(e.target.value)}
                    />
                  </span>
                </div>
                <div className="flex w-full gap-10">
                  <label htmlFor="">
                    <span className="lang-pt">{lang === "pt" ? "MENSAGEM" : null}
                      {lang === "es" ? "MENSAJE" : null}
                      {lang === "en" ? "MESSAGE" : null}</span>:
                  </label>
                  <span
                    className="flex w-full"
                    data-name="mensagem"
                  >
                    <textarea
                      
                      className={`flex w-full text-input pl-2 rounded-md ${shouldShake ? 'shake-effect' : ''}`}
                      id="message"
                      aria-required="true"
                      aria-invalid="false"
                      name="mensagem"
                      defaultValue={""}
                      required
                      onChange={(e) => setMessage(e.target.value)}
                    />
                  </span>
                </div>
                <div className="button-wrapper">
                  <h1 className={`${shouldShake? "": ""} text-lg md:text-xl font-description`}>Preencha os campos obrigatórios</h1>
                  <button className="bg-primary rounded-md p-2 hover:bg-hover my-4 hover:text-secondary" type="button" onClick={SendMessage}>
                    <span className="lang-pt">{lang === "pt" ? "Enviar" : null}
                      {lang === "es" ? "Enviar" : null}
                      {lang === "en" ? "Send" : null}</span>
                  </button>
                  

                </div>

              </form>
            </div>
          </div>
        </div>}


      </div>
      
    </section>
  );
};
import React from 'react';
import { LazyLoadImage } from "react-lazy-load-image-component";



export default function Footer() {

  function sendToLaw() {
    window.location.href = "/law";
  };

  return (
    <div className={`grid md:grid-cols-2 p-20 gap-10 bg-holder text-overholder py-10`}>

      <div className="grid grid-rows-2">
        <div className="">

          <h1 className="font-bold">
            Smart Business Service
          </h1>
            CNPJ: 48.195.528/0001-90
          <br />


          <br />

          <h1 className="font-bold">
            CONTATO
          </h1>
          <p>
            {/*Fone: <br />*/}
            E-mail: smartbservice@gmail.com

          </p>

        </div>
        <div className="footer-address">
          <h1 className="font-bold pt-4">
            ENDEREÇO
          </h1>
          <p>
            Rua Francisco Rocha, 198 
            <br />
            CEP: 80420-130
            <br />
            Curitiba - PR
          </p>

        </div>

      </div>
      <div className="mx-auto center pt-10">
        <p className="text-sm">
          A nossa empresa está comprometida com rigorosas políticas anti lavagem de dinheiro e antifraude. Buscamos garantir a integridade e segurança das transações, adotando medidas robustas para prevenir atividades ilícitas. Para acelerar e facilitar os processos de investigação, fornecemos um canal imediato para comunicação às autoridades.
        </p>
        <button className="bg-primary rounded-md p-2 hover:bg-hover my-4 hover:text-secondary" onClick={sendToLaw}>
          <p className="text-sm ">
            Canal de Comunicação
            <br />para Autoridades
          </p>
        </button>
      </div>

    </div>
  );
};


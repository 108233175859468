export default function NotFound () {

    function redirectHome () {
        window.location.href = "/";
    }

    return (
        <div className="h-screen bg-background m-auto">
            <div className="flex flex-col m-auto text-center mt-40 h-full">
            <img src="/image/logo-brand.png" className="animate-bounce mx-auto" alt="" onClick={redirectHome}/>
        
                <div >
                    <h1 className="text-4xl md:text-6xl font-title text-overbackground">
                        404
                    </h1>
                </div>
                <div >
                    <h1 className="text-4xl md:text-6xl font-description text-overbackground">
                        Página não encontrada
                    </h1>
                </div>

            </div>            

        </div>
    );
};